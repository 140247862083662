/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { DomainForm } from "src/api";
import {
    ListingItem,
    Field,
    offerPlacement,
    Category,
    Image,
} from "@lib/shared/types";
import transform from "lodash/transform";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import { BlogPostType } from "src/api/getBlog";

export const getSecondServiceCategoryIcon = (
    tag: string,
    category: Category,
    form?: DomainForm,
): Image | null => {
    const getSecondServiceCategory = (
        categoryName: string,
        category: Category,
    ) => {
        return category?.secondServiceCategories
            ?.map((el) => el.secondServiceCategory)
            .find((el) => el.name === categoryName);
    };
    let secondCategory;
    if (tag === "undefined") {
        secondCategory = form
            ? getSecondServiceCategory(form.categoryName as string, category)
            : getSecondServiceCategory(tag, category);
    } else {
        secondCategory = getSecondServiceCategory(tag, category);
    }

    if (secondCategory) {
        return secondCategory.icon;
    }
    return null;
};

export const doesOfferHaveALink = (offer: ListingItem): boolean => {
    return (
        (!!offer.attributes?.phoneNumber ||
            !!offer.hiddenAttributes?.phoneNumber ||
            offer.proxiedNumber) &&
        (offer.link as string)?.startsWith("http")
    );
};

export const handlePreventPageClose = (
    e: WindowEventMap["beforeunload"],
): void => {
    e.preventDefault(); // firerFox
    e.returnValue = "";
};

export const differenceBetweenTwoObjects = (
    origObj: {} | null,
    newObj: {} | null,
) => {
    function changes(newObject: {}, original: {}) {
        let arrayIndexCounter = 0;
        return transform(newObject, (result, value, key) => {
            if (!isEqual(value, original[key])) {
                const resultKey = isArray(original) ? arrayIndexCounter++ : key;
                result[resultKey] =
                    isObject(value) && isObject(original[key])
                        ? changes(value, original[key])
                        : value;
            }
        });
    }
    return changes(newObj ?? {}, origObj ?? {});
};

export const getUserMeta = () => {
    const ratio = window.devicePixelRatio || 1;
    const is_touch_device = "ontouchstart" in document.documentElement;
    const touchStatus = is_touch_device ? "touch" : "no-touch";
    const screenSize = `${screen.width}x${screen.height}`;
    const documentDimension = `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`;
    const rw = screen.width * ratio;
    const rh = screen.height * ratio;
    const widthHeightRatio = `${rw}x${rh}`;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;

    const connectionEffectiveType: string =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (connection?.effectiveType as string) ?? "";
    return {
        screenSize,
        documentDimension,
        ratio,
        widthHeightRatio,
        touchStatus,
        connection: connectionEffectiveType,
    };
};

export function hexToRGBA(hex: string, opacity: number): string {
    return (
        "rgba(" +
        (hex = hex.replace("#", ""))
            .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))
            ?.map(function (l) {
                return parseInt(hex.length % 2 ? l + l : l, 16);
            })
            .concat(isFinite(opacity) ? opacity : 1)
            .join(",") +
        ")"
    );
}

export const arrayToKeyedObject = <T extends { [x: string]: string }>(
    arr: T[],
    key: string,
): { [x: string]: T } => {
    return arr.reduce<{ [x: string]: T }>((obj, item) => {
        obj[item[key]] = item;
        return obj;
    }, {});
};

export const gettingYears = (startYear: number, endYear: number): number[] => {
    return new Array(endYear - startYear + 1)
        .fill(0)
        .map((year, index) => endYear - index);
};

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
};

export const getPhoneNumber = (
    offer: ListingItem | undefined,
): string | undefined => {
    if (offer?.textBlocks?.position === "offer-associated-phone-number") {
        return offer?.textBlocks.text;
    }
    if (offer?.proxiedNumber && offer.proxiedNumber.phoneNumber) {
        return offer?.proxiedNumber.phoneNumber;
    }

    if (offer?.isPhoneNumber && offer.phoneLabel) {
        return offer.phoneLabel;
    }

    const hiddenAttributesNumber = offer?.hiddenAttributes?.phoneNumber;
    const attributesNumber = offer?.attributes.phoneNumber;

    return attributesNumber ?? hiddenAttributesNumber ?? "";
};

export const getHomepageHref = (
    query: { ts?: string | undefined },
    locale: string,
    defaultLocale: string | null,
): string | undefined => {
    if (query.ts !== undefined) return undefined;
    if (!defaultLocale || locale === defaultLocale) return "/";
    return `/${locale}`;
};

export const orderListByKeyword = (keyword: string, offers: ListingItem[]) => {
    if (!keyword) {
        return offers;
    }
    if (offers && offers.length > 1) {
        const listItemMatched = offers?.find((item) => {
            if (item.slug.indexOf(keyword.toLocaleLowerCase()) > -1) {
                return item;
            }
        });
        if (listItemMatched) {
            return [
                listItemMatched,
                ...offers.filter((item) => item.slug !== listItemMatched.slug),
            ];
        }
    }
    return offers;
};

const streetAddressFields = ["streetAddress", "streetAddress2"];
export const isStreetAddress = (codeName: string): boolean => {
    return streetAddressFields.includes(codeName);
};
export const getZipCodeField = (fields: Field[]) => {
    return fields.find(
        (f: Field) => f.codeName === "zipCode" || f.codeName === "toZipCode",
    ) as Field;
};

export const monthsArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const replacePlaceholders = (
    text: string,
    args?: { region?: string; category?: string; city?: string },
) => {
    return text
        ?.replace(/\[m\]/gi, monthsArr[new Date().getMonth()])
        ?.replace(/\[y\]/gi, String(new Date().getFullYear()))
        ?.replace(/\[s\]/gi, args?.region ?? "")
        ?.replace(/\[c\]/gi, args?.category ?? "")
        ?.replace(/\[ci\]/gi, args?.city ?? "")
        ?.replace(/\[amp\]/gi, "&")
        ?.replace(/\[com\]/gi, ",")
        ?.replace(/\[sc\]/gi, ";")
        ?.replace(/\[co\]/gi, ":")
        ?.replace(/\[qt\]/gi, '"')
        ?.replace(/\[sqt\]/gi, "'")
        ?.replace(/\[p\]/gi, ".")
        ?.replace(/\[q\]/gi, "?")
        ?.replace(/\[per\]/gi, "%")
        ?.replace(/\[ex\]/gi, "!")
        ?.replace(/\[br\]/gi, "<br>");
};

export const getMainTitlesNavigationFromBody = (html: string) => {
    const regex = /<[^>]+id="([^"]+)"[^>]*>([\s\S]+?)<\/[^>]+>/g;
    const result = [];

    let match;
    while ((match = regex.exec(html))) {
        const [, id, content] = match;

        const textNodes = content.match(/>([^<]+)/g);

        if (textNodes) {
            result.push(
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...textNodes?.map((node) => ({
                    value: node.substring(1).trim(),
                    id,
                })),
            );
        } else {
            result.push({
                value: content.trim(),
                id,
            });
        }
    }

    return result
        .filter((i) => i.value?.length > 1)
        .map((el) => ({
            title: /^\d/.test(el.value)
                ? el.value.replace(/^\d/, "").slice(1)
                : el.value,
            link: `#${el.id}`,
        }));
};

export const extractExcerptFromBody = (body: string, characters: number) => {
    return body
        .replace(/<[^>]+>/g, "")
        .substring(0, characters)
        .trim();
};

export const getDescriptionForBlogPost = (blogPost: BlogPostType) => {
    if (blogPost?.model.excerpt) {
        return extractExcerptFromBody(blogPost?.model.excerpt, 155);
    }
    if (blogPost?.model.body) {
        return extractExcerptFromBody(blogPost?.model.body, 155);
    }
};

const getOfferPlacement = (offer: ListingItem) => {
    if (offer?.hiddenAttributes?.offerPlacement) {
        return JSON.parse(
            offer?.hiddenAttributes?.offerPlacement,
        ) as offerPlacement;
    } else {
        return null;
    }
};

export const getOffersByPlacement = (
    offers: ListingItem[],
    placement:
        | "thankYou"
        | "header"
        | "exitModal"
        | "offersList"
        | "showStickyOffer",
    categorySlug?: string,
) => {
    if (placement === "thankYou") {
        return offers?.filter((offer) => offer?.placements?.showOnThankYou);
    }
    if (placement === "header") {
        return offers?.filter(
            (offer) =>
                offer?.category?.slug === categorySlug &&
                !offer?.placements?.hideNumberOnHeader,
        );
    }
    if (placement === "exitModal") {
        return offers?.filter(
            (offer) => offer?.placements?.displayOnExitWindow,
        );
    }
    if (placement === "offersList") {
        const finalOffersList = offers?.filter((offer) => {
            const offerPlacement = getOfferPlacement(offer);
            if (offerPlacement) {
                return (
                    offer?.features.length &&
                    (offerPlacement?.offersList === undefined
                        ? true
                        : offerPlacement?.offersList)
                );
            }
            return offer?.features.length;
        });
        return finalOffersList;
    }
    if (placement === "showStickyOffer") {
        const stickyOffer = offers?.find((offer) => {
            if (getPhoneNumber(offer)) {
                return offer;
            }
        });
        return stickyOffer ? [stickyOffer] : [];
    }
};

export const finalShowForm = (
    showList: string | undefined,
    showForm: boolean,
    offers: ListingItem[],
) => {
    if (offers.length === 0) {
        return true;
    }

    const offersList = getOffersByPlacement(offers, "offersList");
    if (offersList?.length === 0) {
        return true;
    }

    if (showList && showList === "t") {
        return false;
    }
    if (showList && showList === "f") {
        return true;
    }
    return showForm;
};

export function lightenColor(
    color: string | undefined,
    factor: number,
): string {
    if (!color) {
        return "";
    }
    function clamp(value: number, min: number, max: number) {
        return Math.min(Math.max(value, min), max);
    }

    function rgbToHex(r: number, g: number, b: number) {
        return `#${((1 << 24) | (r << 16) | (g << 8) | b)
            .toString(16)
            .slice(1)}`;
    }

    function hexToRgb(hex: string) {
        hex = hex.replace(/^#/, "");
        return {
            r: parseInt(hex.substr(0, 2), 16),
            g: parseInt(hex.substr(2, 2), 16),
            b: parseInt(hex.substr(4, 2), 16),
        };
    }

    function hslToRgb(h: number, s: number, l: number) {
        let r, g, b;

        if (s === 0) {
            r = g = b = l;
        } else {
            const hueToRgb = (p: number, q: number, t: number) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            };

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;

            r = Math.round(hueToRgb(p, q, h + 1 / 3) * 255);
            g = Math.round(hueToRgb(p, q, h) * 255);
            b = Math.round(hueToRgb(p, q, h - 1 / 3) * 255);
        }

        return { r, g, b };
    }

    function rgbToHsl(r: number, g: number, b: number) {
        (r /= 255), (g /= 255), (b /= 255);
        const max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        let h,
            s,
            // eslint-disable-next-line prefer-const
            l = (max + min) / 2;

        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            //@ts-ignore
            h /= 6;
        }

        return { h, s, l };
    }

    if (color.startsWith("#")) {
        const rgb = hexToRgb(color);
        const newR = clamp(rgb.r + 255 * factor, 0, 255);
        const newG = clamp(rgb.g + 255 * factor, 0, 255);
        const newB = clamp(rgb.b + 255 * factor, 0, 255);
        return rgbToHex(newR, newG, newB);
    } else if (color.startsWith("rgb")) {
        const matches = color.match(/\d+/g);
        if (!matches || matches.length !== 3) {
            throw new Error("Invalid RGB color format");
        }
        const r = parseInt(matches[0], 10);
        const g = parseInt(matches[1], 10);
        const b = parseInt(matches[2], 10);
        const newR = clamp(r + 255 * factor, 0, 255);
        const newG = clamp(g + 255 * factor, 0, 255);
        const newB = clamp(b + 255 * factor, 0, 255);
        return `rgb(${newR}, ${newG}, ${newB})`;
    } else if (color.startsWith("hsl")) {
        const matches = color.match(/[\d.]+/g);
        if (!matches || matches.length !== 3) {
            throw new Error("Invalid HSL color format");
        }
        const h = parseFloat(matches[0]) / 360;
        const s = parseFloat(matches[1]) / 100;
        const l = parseFloat(matches[2]) / 100;
        const newHsl = hslToRgb(h, s, l);
        const newR = Math.round(clamp(newHsl.r + 255 * factor, 0, 255));
        const newG = Math.round(clamp(newHsl.g + 255 * factor, 0, 255));
        const newB = Math.round(clamp(newHsl.b + 255 * factor, 0, 255));
        const newHslColor = rgbToHsl(newR, newG, newB);
        //@ts-ignore
        const newHue = Math.round(newHslColor.h * 360);
        const newSaturation = Math.round(newHslColor.s * 100);
        const newLightness = Math.round(newHslColor.l * 100);
        return `hsl(${newHue}, ${newSaturation}%, ${newLightness}%)`;
    } else {
        return "#eee";
    }
}

export const parseToDollars = (val: string) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(parseInt(val, 10));
export const injectDoNotSellInFooter = ({
    footerArray,
    trafficSource,
    pageType,
    hideDoNotSell,
}: {
    footerArray: {}[];
    trafficSource: string | null;
    pageType: string;
    hideDoNotSell: boolean | undefined;
}) => {
    if (!trafficSource && pageType !== "do-not-sell" && !hideDoNotSell) {
        footerArray.push({
            title: "Do Not Sell",
            onClick: () => (window.location.href = `/do-not-sell`),
        });
    }
};

export const slugToTitle = (slug?: string) => {
    if (!slug) return "";
    const words = slug.split("-");

    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(" ");
};

export const extractContentFromHtml = (s: string | undefined): string => {
    if (s) {
        const span = document.createElement("span");
        span.innerHTML = s;
        return span.textContent || span.innerText;
    }
    return s || "";
};

export const shouldRemoveBuyer = (
    eb: string | string[] | undefined,
    offer: ListingItem,
): boolean => {
    if (eb && offer?.buyer?.id) {
        return eb.includes(offer?.buyer?.id + "");
    }
    return false;
};

export const commonPartnerWithUsAllowedCategories = ["auto-accident"];
